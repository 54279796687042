<template>
  <section class="dtc-main-section main-container">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="在學期間" active>
            <div class="dashboard">
              <LearningStatistical></LearningStatistical>
            </div>
          </b-tab>
          <b-tab title="訓練期間">
            <div class="dashboard">
              <TrainStatistical></TrainStatistical>
            </div>
          </b-tab>
          <b-tab title="服務期間">
            <div class="dashboard">
              <ServeStatistical></ServeStatistical>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
  </section>
</template>

<script>
import LearningStatistical from "./LearningStatistical";
import TrainStatistical from "./TrainStatistical";
import ServeStatistical from "./ServeStatistical";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import { store } from "@/store/global.js";

const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
export default {
  name: "App",
  components: {
    LearningStatistical,
    TrainStatistical,
    ServeStatistical,
  },
};

</script>

<style lang="scss" scoped>
.main-container {
  position: relative;
}
::v-deep .card-body {
  padding: 0px;
}
::v-deep .dtc-ngrid.field-item {
  font-size: 14px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: keep-all;
  text-overflow: ellipsis;
  padding: 3px 0;
  -webkit-box-orient: vertical;
  text-align: center;
  > span {
    display: block;
  }
}
//  :style="i % 2 == 0 ? 'background-color: #F5F5F5;' : 'background-color: #E0E0E0;'"

.mask {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #00000022;
  z-index: 1;
}
.table-popup {
  // width: 1800px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  border: 1px solid #00000044;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 860px;
  min-width: 100px;
  min-height: 100px;
  background-color: white;
  z-index: 1;
  > div {
    padding: 10px;
    min-height: 300px;
    max-height: 450px;
    overflow: auto;
  }
  overflow: auto;
}
.dashboard {
  width: 100%;
  background: gray;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 5px;
  > main {
    display: grid;
    border-radius: 10px;
    background: white;
    min-width: 420px;
    max-height: 45vh;
    height: 47vh;
    grid-template-rows: 30px calc(100% - 60px);
    grid-template-columns: calc(100% - 5px);
    padding: 10px;
    color: black;
    gap: 1rem;
    h6 {
      font-size: 18px;
    }
  }
}
@media (max-width: 1300px) {
  .dashboard {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .dashboard {
    grid-template-columns: repeat(1, 1fr);
  }
}

aside {
  display: grid;
  grid-template-columns: 238px repeat(2, 1fr);
}
.open-table {
  cursor: pointer;
  // color: #6b645a;
  color: #6b645ac7;
  margin: 2px auto 0;
  font-size: 17.5px;
  font-weight: 500;
}
::v-deep .btn-light {
  // background: #165b9b;
  // border-color: #165b9b;
  background: #7d2da1;
  border-color: #7d2da1;

  color: white;
}
</style>
