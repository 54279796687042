<template>
  <section class="dtc-main-section">
    <div class="p-5" style="background: white; border-radius: 10px">
      <h5 class="mb-5">
        公費醫學生人數
        <b-button
          variant="outline-primary float-right mr-5"
          @click="getExcel"
          v-if="!loadingExcel"
          >匯出清單</b-button
        >
        <b-button variant="outline-primary float-right mr-5" disabled v-else>
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </b-button>
      </h5>
      <table>
        <thead class="dtc-header">
          <th>
            {{ "縣市" }}
          </th>
          <th>
            {{ "學校" }}
          </th>
          <th>
            {{ "科系" }}
          </th>
          <th>
            {{ "人數" }}
          </th>
        </thead>
        <tbody class="dtc-body">
          <tr v-for="(item, i) in items" :key="i">
            <td v-show="item.isfirst" :rowspan="item.ct">
              {{ item.schooltown || "" }}
            </td>
            <td v-show="item.isfirstS" :rowspan="item.st">
              {{ item.school || "" }}
            </td>
            <td>
              {{ item.majorName || "" }}
            </td>
            <td>
              {{ item.count || "" }}
            </td>
          </tr>
        </tbody>
        <tfoot class="dtc-foot">
          <tr>
            <td colspan="3">總計</td>
            <td>{{ totalcount }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </section>
</template>
<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import { domainObject } from "@/constant.js";
const headers = [
  { name: "縣市", key: "", sortDesc: null },
  { name: "學校", key: "", sortDesc: null },
  { name: "科系", key: "", sortDesc: null },
  { name: "人數", key: "", sortDesc: null },
];

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      totalcount: 0,

      loadingExcel: false,
      domainObject,
    };
  },
  methods: {
    async getExcel() {
      try {
        this.loadingExcel = true;
        const res = await window.axios.get(
          "StudentInfo/GetStudentStatisticalExport",
          { params: { serviceItem: "訓練期間", hasmajor: true } }
        );
        this.loadingExcel = false;
        const excelUrl = `${this.domainObject.platformOriginal}${res}`;
        window.open(excelUrl);
      } catch (error) {
        this.$bvToast.toast(error, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getData() {
      try {
        const arr = await window.axios.get(
          "StudentInfo/GetStudentStatistical",
          { params: { serviceItem: "訓練期間", hasmajor: true } }
        );
        let tc = "";
        let sc = "";
        this.items = arr.map((s) => {
          let f = false;
          let sn = false;
          this.totalcount += s.Count;
          if (tc == s.SchoolCounty) {
            f = false;
          } else {
            tc = s.SchoolCounty;
            f = true;
          }
          
          if (sc == s.SchoolCode) {
            sn = false;
          } else {
            sc = s.SchoolCode;
            sn = true;
          }
          return {
            school: this.schoolObject.find((k) => +k.value == +s.SchoolCode)
              ? this.schoolObject.find((k) => +k.value == +s.SchoolCode).text
              : "",
            schooltown: s.SchoolCounty,
            majorName: s.MajorName,
            count: s.Count,
            isfirst: f,
            ct: arr.filter((x) => x.SchoolCounty == s.SchoolCounty).length,
            isfirstS: sn,
            st: arr.filter((x) => x.SchoolCode == s.SchoolCode).length,
          };
        });
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllSchool() {
      const arr = await window.axios.get("School/GetAll");
      this.schoolObject = arr.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
      }));
      this.schoolObject.unshift({ value: null, text: "全部" });
    },
  },
  async mounted() {
    await this.getAllSchool();
    await this.getData();
    this.$root.$on("get-Newest-LocalstuData", () => this.getData());
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  // overflow-x: auto;
  padding: 12px;
  // position: relative;
  // height: 98vh;
}
.dtc-header {
  width: 960px;
  text-align: center;
  border-right: 0px;

  th {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: bolder;
    padding: 4px 0;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    height: 36px;
  }
  th:nth-child(1) {
    border-left: 2px solid white;
    background: #ffc000;
    width: 170px;
  }
  th:nth-child(2) {
    background: #91d14f;
    width: 330px;
  }
  th:nth-child(3) {
    background: #91d14f;
    width: 330px;
  }
  th:nth-child(4) {
    background: #d0eef6;
    width: 120px;
  }
}
.dtc-body {
  width: 960px;
  text-align: center;
  border-right: 0px;

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: bolder;
    padding: 4px 0;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    height: 36px;
  }
  td:nth-child(1) {
    border-left: 2px solid white;
    background: #ffc000;
    width: 170px;
  }
  td:nth-child(2) {
    background: #91d14f;
    width: 330px;
  }
  td:nth-child(3) {
    background: #91d14f;
    width: 330px;
  }
  td:nth-child(4) {
    background: #d0eef6;
    width: 120px;
  }
}
.dtc-foot {
  width: 960px;
  text-align: center;
  border-right: 0px;

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: bolder;
    padding: 4px 0;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    height: 36px;
  }
  td:first-child {
    border-left: 2px solid white;
    background: #95b3d6;
    width: 500px;
  }
  td:last-child {
    background: #d0eef6;
    width: 120px;
  }
}
</style>
